import { Array, Literal, Number, Record, Static, Union } from "runtypes";

import { DisplayTableColumnId } from "../idTypeBrands";
import { getNormalEnum } from "../runtypeEnums";

import {
  BinaryColumnPredicate,
  BinaryColumnPredicateOpLiteral,
  ListBinaryColumnPredicate,
  ListBinaryColumnPredicateOpLiteral,
  UnaryColumnPredicate,
  UnaryColumnPredicateOpLiteral,
} from "./columnPredicateTypes";

export const ConditionalFormattingRulesetTypeLiteral = Union(
  Literal("GRADIENT"),
  Literal("RULES"),
);
export type ConditionalFormattingRulesetType = Static<
  typeof ConditionalFormattingRulesetTypeLiteral
>;
export const ConditionalFormattingRulesetType = getNormalEnum(
  ConditionalFormattingRulesetTypeLiteral,
);

const ConditionalFormattingColumnFilterTypeLiteral = Union(
  Literal("EXCLUDE"),
  Literal("INCLUDE"),
);
export type ConditionalFormattingColumnFilterType = Static<
  typeof ConditionalFormattingColumnFilterTypeLiteral
>;
export const ConditionalFormattingColumnFilterType = getNormalEnum(
  ConditionalFormattingColumnFilterTypeLiteral,
);

export const ExcludeColumnFilter = Record({
  type: Literal(ConditionalFormattingColumnFilterType.EXCLUDE),
  excludedColumns: Array(DisplayTableColumnId),
});

export const IncludeColumnFilter = Record({
  type: Literal(ConditionalFormattingColumnFilterType.INCLUDE),
  includedColumns: Array(DisplayTableColumnId),
});

export const ConditionalFormattingColumnFilter = Union(
  /**
   * Note: Elements of excludedColumns/includedColumns refer to DataFrame
   * column names _before_ renames are applied. In some parts of the code
   * (like the UI) these values are referred to as columnIds.
   */
  ExcludeColumnFilter,
  IncludeColumnFilter,
);
export type ConditionalFormattingColumnFilter = Static<
  typeof ConditionalFormattingColumnFilter
>;

// TODO(EXP-1346): Consider adding the workspace palette colors as
// an option to the conditional formatting styles.
// WARN: Do not use BLUE, PURPLE, PINK, LIME, GRAY, or TURQUOISE
// colors (yet). See EXP-1364 for details.
export const ConditionalFormattingCellStyleLiteral = Union(
  Literal("RED"),
  Literal("ORANGE"),
  Literal("YELLOW"),
  Literal("GREEN"),
  Literal("BLUE"),
  Literal("PURPLE"),
  Literal("PINK"),
  Literal("LIME"),
  Literal("GRAY"),
  Literal("TURQUOISE"),
);
export type ConditionalFormattingCellStyle = Static<
  typeof ConditionalFormattingCellStyleLiteral
>;
export const ConditionalFormattingCellStyle = getNormalEnum(
  ConditionalFormattingCellStyleLiteral,
);

// TODO: define more gradient types
const ConditionalFormattingGradientStyleLiteral = Union(
  Literal("GREEN_WHITE"),
  Literal("WHITE_GREEN"),
  Literal("RED_WHITE"),
  Literal("WHITE_RED"),
  Literal("GREEN_WHITE_RED"),
  Literal("RED_WHITE_GREEN"),
  Literal("GREEN_YELLOW_RED"),
  Literal("RED_YELLOW_GREEN"),
  Literal("BLUE_WHITE"),
  Literal("WHITE_BLUE"),
  Literal("RED_WHITE_BLUE"),
  Literal("BLUE_WHITE_RED"),
);
export type ConditionalFormattingGradientStyle = Static<
  typeof ConditionalFormattingGradientStyleLiteral
>;
export const ConditionalFormattingGradientStyle = getNormalEnum(
  ConditionalFormattingGradientStyleLiteral,
);

const BaseConditionalFormattingRuleset = Record({
  type: ConditionalFormattingRulesetTypeLiteral,
  columns: ConditionalFormattingColumnFilter,
});

/* eslint-disable tree-shaking/no-side-effects-in-initialization */
export const GradientConditionalFormattingRuleset =
  BaseConditionalFormattingRuleset.extend({
    type: Literal(ConditionalFormattingRulesetType.GRADIENT),
    style: ConditionalFormattingGradientStyleLiteral,
  });
export type GradientConditionalFormattingRuleset = Static<
  typeof GradientConditionalFormattingRuleset
>;

export const ConditionalFormattingColumnPredicateOpLiteral = Union(
  BinaryColumnPredicateOpLiteral,
  UnaryColumnPredicateOpLiteral,
  ListBinaryColumnPredicateOpLiteral,
);
export type ConditionalFormattingColumnPredicateOp = Static<
  typeof ConditionalFormattingColumnPredicateOpLiteral
>;

const ConditionalFormattingColumnPredicate = Union(
  UnaryColumnPredicate,
  BinaryColumnPredicate,
  ListBinaryColumnPredicate,
);

export const RulesConditionalFormattingRulesetRule = Record({
  expression: ConditionalFormattingColumnPredicate,
  style: ConditionalFormattingCellStyleLiteral,
});
export type RulesConditionalFormattingRulesetRule = Static<
  typeof RulesConditionalFormattingRulesetRule
>;

export const RulesConditionalFormattingRuleset =
  BaseConditionalFormattingRuleset.extend({
    type: Literal(ConditionalFormattingRulesetType.RULES),
    rules: Array(RulesConditionalFormattingRulesetRule),
  });
export type RulesConditionalFormattingRuleset = Static<
  typeof RulesConditionalFormattingRuleset
>;

export const ConditionalFormattingRuleset = Union(
  GradientConditionalFormattingRuleset,
  RulesConditionalFormattingRuleset,
);
export type ConditionalFormattingRuleset = Static<
  typeof ConditionalFormattingRuleset
>;

export const ConditionalFormattingDefinition = Record({
  rulesets: Array(ConditionalFormattingRuleset),
});
export type ConditionalFormattingDefinition = Static<
  typeof ConditionalFormattingDefinition
>;

// #region Types for conditional formatting rules after the server has computed metadata
export const GradientConditionalFormattingRulesetWithMetadata =
  GradientConditionalFormattingRuleset.extend({
    metadata: Record({
      // The smallest value in all the gradient data
      min: Number,
      // The largest value in all the gradient data
      max: Number,
    }),
  });
export type GradientConditionalFormattingRulesetWithMetadata = Static<
  typeof GradientConditionalFormattingRulesetWithMetadata
>;

export const RulesConditionalFormattingRulesetWithMetadata =
  RulesConditionalFormattingRuleset.extend({
    // nothing meaningful the server needs to calculate for these as of now
    metadata: Record({}),
  });
export type RulesConditionalFormattingRulesetWithMetadata = Static<
  typeof RulesConditionalFormattingRulesetWithMetadata
>;

export const ConditionalFormattingRulesetWithMetadata = Union(
  GradientConditionalFormattingRulesetWithMetadata,
  RulesConditionalFormattingRulesetWithMetadata,
);
export type ConditionalFormattingRulesetWithMetadata = Static<
  typeof ConditionalFormattingRulesetWithMetadata
>;

export const ConditionalFormattingDefinitionWithMetadata = Record({
  rulesets: Array(ConditionalFormattingRulesetWithMetadata),
});
export type ConditionalFormattingDefinitionWithMetadata = Static<
  typeof ConditionalFormattingDefinitionWithMetadata
>;
// #endregion
